/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h3: "h3",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "스탠다드아웃 98번째 로그에서는 인프런 강좌, 아이맥, 당근마켓 채용 라이브 등에 대해서 이야기를 나눴습니다."), "\n", React.createElement(_components.h3, null, "44bits가 참여한 행사들"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.apple.com/kr/apple-events/september-2020/?useASL=true"
  }, "애플 라이브 이벤트")), "\n"), "\n", React.createElement(_components.h3, null, "당근마켓이 참여하는 행사들"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://jsconfkorea.com/ko"
  }, "JS Conf")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.wanted.jobs/events/wantedcon08"
  }, "원티드 콘")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://slack.opencontainer.co.kr/"
  }, "SRE Seoul Meetup")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://pages.awscloud.com/aws-community-day-online-2020.html"
  }, "AWS 커뮤니티 데이 온라인 - 앱 현대화 특집")), "\n"), "\n", React.createElement(_components.h3, null, "노션에 백링크 추가"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/NotionHQ/status/1306677709263630336"
  }, "트위터 공지")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ko.wikipedia.org/wiki/%EC%A0%9C%EB%84%88%EB%91%90_%ED%94%84%EB%A1%9C%EC%A0%9D%ED%8A%B8"
  }, "Project Xanadu")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ko.wikipedia.org/wiki/%EB%A9%94%EB%A9%95%EC%8A%A4"
  }, "Memex")), "\n"), "\n", React.createElement(_components.h3, null, "넷플릭스 다큐멘터리 ", React.createElement(_components.a, {
    href: "https://www.netflix.com/kr/title/81254224"
  }, "소셜 딜레마")), "\n", React.createElement(_components.h3, null, "Github CLI 1.0 출시"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/github/status/1306586113293729795"
  }, "트위터 공지")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
